import {getEventUrl} from '../../../../../commons/selectors/event'
import {getEvent} from '../../selectors/event'
import {DetailsPageAppProps} from '../app/interfaces'
import {connect} from '../runtime-context/context'
import {SocialShareOwnProps, SocialShareRuntimeProps} from './interfaces'
import {SocialShare as Presentation} from './social-share'

const mapRuntime = ({state, pageUrl, actions: {shareEvent}}: DetailsPageAppProps): SocialShareRuntimeProps => {
  const event = getEvent(state)

  return {
    eventUrl: getEventUrl(pageUrl, event, state.multilingual),
    shareEvent,
  }
}

export const SocialShare = connect<SocialShareOwnProps, SocialShareRuntimeProps>(mapRuntime)(Presentation)
