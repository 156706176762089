import {getActiveElement} from '../../selectors/focus-handler'
import {connect} from '../runtime-context/connect'
import {FocusHandler as Presentation} from './focus-handler'
import type {FocusHandlerRuntimeProps} from './interfaces'

const mapRuntime = ({state, actions: {setActiveElement}}: any): FocusHandlerRuntimeProps => ({
  activeElement: getActiveElement(state.focusHandler),
  setActiveElement,
})

export const FocusHandler = connect<any, any>(mapRuntime)(Presentation)
