import {useEnvironment} from '@wix/yoshi-flow-editor'
import classNames from 'classnames'
import React from 'react'
import cl from '../classnames.scss'
import s from './subtitle.scss'

export interface SubtitleProps {
  dataHook: string
  text: string
  customColor?: string
  compact?: boolean
  className?: string
}

export const Subtitle = ({dataHook, className, text, customColor, compact}: SubtitleProps) => {
  const {isMobile: mobile} = useEnvironment()

  return (
    <h2
      className={classNames(
        cl.evSubtitlesFont,
        customColor || cl.evSubtitlesColor,
        mobile ? s.mobile : s.desktop,
        compact ? s.compact : '',
        s.upgrade,
        className,
      )}
      data-hook={dataHook}
    >
      {text}
    </h2>
  )
}
