import React from 'react'
import {DetailsPageRuntimeContext} from '../components/runtime-context/context'
import {State} from '../types/state'

export function useAppSelector<T>(selector: (state: State) => T): T {
  return selector(React.useContext(DetailsPageRuntimeContext).state)
}

export const useAppActions = () => React.useContext(DetailsPageRuntimeContext).actions

export const useAppHost = () => React.useContext(DetailsPageRuntimeContext).host
