import {hookToAttributeSelector} from '@wix/panda-js-utils'
import {detailsPageDataHooks as DH} from '@wix/wix-events-data-hooks'
import React from 'react'
import {RsvpInfoButton} from '../rsvp-info-button/rsvp-info-button'
import {EditRegistrationProps} from './interfaces'

export const EditRegistrationButton: React.FC<EditRegistrationProps> = ({
  t,
  buttonTextTranslation,
  messageTranslation,
  changeMemberRsvp,
  memberAbleToFinishRsvp,
  openConfirmRsvp,
}) => {
  const handleClick = () => {
    if (memberAbleToFinishRsvp) {
      openConfirmRsvp()
    } else {
      changeMemberRsvp(hookToAttributeSelector(DH.RSVP_INFO_BUTTON))
    }
  }

  return (
    <RsvpInfoButton
      messages={{
        exploreEventsActionLabel: t(buttonTextTranslation),
        message: t(messageTranslation),
      }}
      dataHook={DH.UPDATE_MEMBER_EVENT}
      onClick={handleClick}
    />
  )
}
