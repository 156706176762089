import classNames from 'classnames'
import React from 'react'
import c from '../../../../classnames.scss'
import s from '../list.scss'
import {TimeProps} from './interfaces'

export const Time = ({time, duration}: TimeProps) => (
  <div>
    <span className={classNames(s.scheduleTime, c.scheduleTimeFont, c.scheduleTimeColor)}>
      <time dateTime={time}>{time}</time>
    </span>
    <br role="none" />
    <span className={classNames(s.scheduleDuration, c.scheduleDurationFont, c.scheduleDurationColor)}>{duration}</span>
  </div>
)
