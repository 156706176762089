import {addQueryParams} from '@wix/panda-js-utils'
import {CommonState, Multilingual} from '../types/state'

export const getEventUrl = (pageUrl: string = '', event: wix.events.Event, multilingual: Multilingual) => {
  const url = `${pageUrl}/${event.slug}`

  if (multilingual.isEnabled) {
    const lang = multilingual.currentLanguage
    return addQueryParams(url, {lang})
  }

  return url
}

export const getEventDateInformation = (state: CommonState, id: string) => state.dates.events[id]

export const isMultiDay = (state: CommonState, event: wix.events.Event) => {
  const events = state.dates.events
  const eventId = event.id
  const multiDay = events[eventId].multiDayInfo?.duration > 1

  return multiDay
}
