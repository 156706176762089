import {getEventTitle} from '@wix/wix-events-commons-statics'
import {getEvent} from '../../../../selectors/event'
import {DetailsPageAppProps} from '../../../app/interfaces'
import {connect} from '../../../runtime-context/context'
import {TitleOwnProps, TitleRuntimeProps} from './interfaces'
import {Title as TitlePresentation} from './title'

const mapRuntime = ({state}: DetailsPageAppProps): TitleRuntimeProps => ({
  title: getEventTitle(getEvent(state)),
})

export const Title = connect<TitleOwnProps, TitleRuntimeProps>(mapRuntime)(TitlePresentation)
