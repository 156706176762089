import {HEADER_LAYOUT} from '@wix/wix-events-commons-statics'
import React from 'react'
import {ClassicLayout} from './classic-layout/classic-layout'
import {HeaderProps} from './interfaces'
import {SideBySideLayout} from './side-by-side-layout/side-by-side'

export const Header: React.FC<HeaderProps> = ({onClickRegistrationButton, headerLayout}) => {
  return headerLayout === HEADER_LAYOUT.SIDE_BY_SIDE ? (
    <SideBySideLayout onClickRegistrationButton={onClickRegistrationButton} />
  ) : (
    <ClassicLayout onClickRegistrationButton={onClickRegistrationButton} />
  )
}
