import {useEnvironment, usePanorama} from '@wix/yoshi-flow-editor'

const MOBILE_MAX_SIZE = 750

export const useFullWidth = () => {
  const {isMobile: mobile, dimensions} = useEnvironment()

  if (mobile) {
    return false
  }

  return String(dimensions.width) === '' || String(dimensions.width) === '100%'
}

export const useMobileSize = () => {
  const {isMobile: mobile, dimensions, isEditorX: responsive, isSSR} = useEnvironment()
  const logger = usePanorama()?.logger?.()
  const fullWidth = useFullWidth()

  if (responsive) {
    return mobile
  }

  const mobileSize = !fullWidth && dimensions.width < MOBILE_MAX_SIZE

  if (!isSSR && !mobile && mobileSize) {
    logger?.debug?.('Narrow layout details page')
  }

  return mobileSize
}
