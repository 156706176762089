import {withTranslation} from '@wix/yoshi-flow-editor'
import {getEvent} from '../../selectors/event'
import {getModalType} from '../../selectors/modals'
import {DetailsPageAppProps} from '../app/interfaces'
import {connect} from '../runtime-context/context'
import {ModalsRuntimeProps} from './interfaces'
import {Modals as Presentation} from './modals'

const mapRuntime = ({state, host}: DetailsPageAppProps): ModalsRuntimeProps => ({
  modalType: getModalType(state),
  plan: state.seating?.plan,
  eventId: getEvent(state)?.id,
  blockScroll: host.blockScroll,
  unblockScroll: host.unblockScroll,
})

export const Modals = connect<{}, ModalsRuntimeProps>(mapRuntime)(withTranslation()(Presentation))
