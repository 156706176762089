import {useEnvironment} from '@wix/yoshi-flow-editor'
import React from 'react'
import {useSettings} from '../../../../hooks/settings'
import {settingsParams} from '../../../../settingsParams'
import {Subtitle} from '../../subtitle/subtitle'
import {SocialShare} from '../index'
import {SocialShareOwnProps} from '../interfaces'
import ss from '../social-share.scss'
import s from './with-title.scss'

export const SocialShareWithTitle = ({t, page}: SocialShareOwnProps) => {
  const settings = useSettings()
  const shareTitleText = settings.get(settingsParams.shareTitleText)
  const {isMobile: mobile} = useEnvironment()

  return (
    <div className={s.container}>
      <Subtitle className={mobile ? s.subtitle : null} dataHook="social-share" text={shareTitleText} />
      <div className={ss.container}>
        <SocialShare t={t} page={page} />
      </div>
    </div>
  )
}
