import {isExternal, isWithoutRegistration} from '@wix/wix-events-commons-statics'
import {isEditor} from '../../../../commons/selectors/environment'
import {isEventsInMembersInstalled} from '../../../../commons/selectors/installed-apps'
import {State} from '../types/state'
import {getEvent, hasGuestsGoing} from './event'

export const isMembersVisible = (state: State) => !isExternal(getEvent(state)) && isEventsInMembersInstalled(state)

export const isMembersVisibleWithGuests = (state: State) =>
  isMembersVisible(state) && (hasGuestsGoing(state) || isEditor(state)) && !isWithoutRegistration(getEvent(state))
