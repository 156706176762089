import {isTicketed} from '@wix/wix-events-commons-statics'
import {isEditor} from '../../../../../commons/selectors/environment'
import {getEvent} from '../../selectors/event'
import {getReservationState, getRoute} from '../../selectors/navigation'
import {DetailsPageAppProps} from '../app/interfaces'
import {connect} from '../runtime-context/context'
import {RouterRuntimeProps} from './interfaces'
import {Router as Presentation} from './router'

const mapRuntimeToProps = ({state}: DetailsPageAppProps): RouterRuntimeProps => {
  const event = getEvent(state)

  return {
    route: getRoute(state),
    reservationState: getReservationState(state),
    isTicketed: !!event && isTicketed(event),
    isEditor: isEditor(state),
  }
}

export const Router = connect<{}, RouterRuntimeProps>(mapRuntimeToProps)(Presentation)
