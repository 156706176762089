import {IStyleParam} from '@wix/tpa-settings'
import {
  EventDetailsAlignment,
  EventDetailsButtonStyle,
  EventDetailsImageRatio,
  HEADER_IMAGE_ALIGNMENT,
  HEADER_LAYOUT,
  IMAGE_SCALING,
  TICKET_PICKER_LAYOUT,
} from '@wix/wix-events-commons-statics'
import {CustomCssVarsFn} from '@wix/yoshi-flow-editor'
import {remapColor, remapFont} from '../../commons/custom-css-utils'
import {degreesToRadians} from '../../commons/utils/math'
import {StyleSelectors} from './style-selectors'
import stylesParams, {DetailsStyleParams} from './stylesParams'

export const customCssVars: CustomCssVarsFn<DetailsStyleParams> = ({styleParams, isRTL: RTL, isMobile}) => {
  const styles = {
    get: (param: IStyleParam) => styleParams.booleans[param.key] ?? styleParams.numbers[param.key],
  }

  const styleSelectors = StyleSelectors(styles.get)

  const isFitImage = () => styles.get(stylesParams.imageScaling) === IMAGE_SCALING.FIT

  const isSquareImage = () =>
    (!isMobile && styles.get(stylesParams.headerLayout) === HEADER_LAYOUT.SIDE_BY_SIDE) ||
    styles.get(stylesParams.imageRatio) === EventDetailsImageRatio['1:1']

  const getTextAlignment = (param: IStyleParam) => {
    const alignment = styles.get(param)
    if (alignment === EventDetailsAlignment.LEFT) {
      return 'left'
    } else if (alignment === EventDetailsAlignment.RIGHT) {
      return 'right'
    }
    return 'center'
  }

  const isLeftAligned = (param: IStyleParam) => styles.get(param) === EventDetailsAlignment.LEFT
  const isRightAligned = (param: IStyleParam) => styles.get(param) === EventDetailsAlignment.RIGHT
  const isCenterAligned = (param: IStyleParam) => styles.get(param) === EventDetailsAlignment.CENTER

  const getTimeAndLocationContainerDir = () => {
    if (isCenterAligned(stylesParams.contentAlignment)) {
      return 'column'
    }
    return RTL ? 'row-reverse' : 'row'
  }

  const getContentListItemPosition = () => {
    if (RTL) {
      return isRightAligned(stylesParams.contentAlignment) ? 'outside' : 'inside'
    }
    return isLeftAligned(stylesParams.contentAlignment) ? 'outside' : 'inside'
  }

  const isPageRegistrationButtonVisible = () => styles.get(stylesParams.pageRegistrationButtonVisible)

  const isRoundedButton = (buttonStyle: EventDetailsButtonStyle) =>
    [EventDetailsButtonStyle.FULL_ROUNDED, EventDetailsButtonStyle.HOLLOW_ROUNDED].includes(buttonStyle)

  const isHollowButton = (buttonStyle: EventDetailsButtonStyle) =>
    [EventDetailsButtonStyle.HOLLOW, EventDetailsButtonStyle.HOLLOW_ROUNDED].includes(buttonStyle)

  const isRegistrationButtonHollow = () => isHollowButton(styles.get(stylesParams.buttonStyle))
  const isRegistrationButtonRounded = () => isRoundedButton(styles.get(stylesParams.buttonStyle))

  const isFormButtonHollow = () => isHollowButton(styles.get(stylesParams.formButtonStyle))
  const isFormButtonRounded = () => isRoundedButton(styles.get(stylesParams.formButtonStyle))

  const isTicketPickerStyleList = () => styles.get(stylesParams.ticketPickerLayout) === TICKET_PICKER_LAYOUT.LIST

  const getTicketPickerPadding = () => {
    if (isTicketPickerStyleList()) {
      return `calc(var(--ticketPickerListSpacing) * 1px) ${isMobile ? '20px' : '0'}`
    }
    return isMobile ? '20px' : '32px'
  }

  const getTicketPickerShadow = () => {
    if (isTicketPickerStyleList() || !styles.get(stylesParams.ticketPickerCardsShadowVisible)) {
      return 'none'
    }
    const round = n => Math.round(n * 10) / 10
    const distance = styles.get(stylesParams.ticketPickerCardsShadowDistance)
    const angle = degreesToRadians(styles.get(stylesParams.ticketPickerCardsShadowAngle))
    const yOffset = round(Math.cos(angle) * distance * -1)
    const xOffset = round(Math.sin(angle) * distance)
    return `${xOffset}px ${yOffset}px calc(var(--ticketPickerCardsShadowBlur) * 1px) rgb(var(--ticketPickerCardsShadowColor))`
  }

  const getTicketPickerBorderCollapseWidth = () => {
    /* if card spacing is 0 and there is a border, collapse borders by adding negative margin */
    if (isTicketPickerStyleList()) {
      return '0'
    }
    const spacing = styles.get(stylesParams.ticketPickerCardSpacing)
    if (spacing !== 0) {
      return '0'
    }
    const borderWidth = styles.get(stylesParams.ticketPickerCardsBorderWidth)
    return -1 * borderWidth
  }

  return {
    shortDateLocationVisibility: styleSelectors.isShortDateLocationVisible() ? 'block' : 'none',
    descriptionVisibility: styleSelectors.isDescriptionVisible() ? 'block' : 'none',
    membersVisibility: styleSelectors.isMembersVisible() ? 'block' : 'none',
    membershipsVisibility: styleSelectors.isMembershipsVisible() ? 'block' : 'none',
    aboutSectionLargerBottomMargin: styleSelectors.isMembershipsVisible() ? '42px' : '0',
    groupVisibility: styleSelectors.isGroupVisible() ? 'block' : 'none',
    aboutSectionVisibility: styleSelectors.isAboutSectionVisible() ? 'block' : 'none',
    groupActivityWithAboutSpacingMobile: styleSelectors.isAboutSectionVisible() ? '24px' : '32px',
    groupActivityWithAboutSpacingDesktop: styleSelectors.isAboutSectionVisible() ? '28px' : '32px',
    headerImageFlexDirection:
      styles.get(stylesParams.headerImageAlignment) === HEADER_IMAGE_ALIGNMENT.LEFT ? 'row' : 'row-reverse',
    isSquareImage: isSquareImage() ? 1 : 0,
    fitImageVisibility: isFitImage() ? 'flex' : 'none',
    coverImageVisibility: !isFitImage() ? 'flex' : 'none',
    scheduleVisibility: styleSelectors.isScheduleVisible() ? 'block' : 'none',
    socialShareVisibility: styleSelectors.isSocialShareVisible() ? 'block' : 'none',
    headerTextAlignment: getTextAlignment(stylesParams.headerAlignment),
    contentTextAlignment: getTextAlignment(stylesParams.contentAlignment),
    contentListItemPosition: getContentListItemPosition(),
    isContentCentered: isCenterAligned(stylesParams.contentAlignment) ? 1 : 0,
    timeAndLocationContainerDir: getTimeAndLocationContainerDir(),
    pageRegistrationButtonVisibility: isPageRegistrationButtonVisible() ? 'block' : 'none',
    headerRegistrationButtonVisibility: styleSelectors.isHeaderRegistrationButtonVisible() ? 'block' : 'none',
    mapVisibility: styleSelectors.isMapVisible() ? 'block' : 'none',
    readMoreButtonVisibility: styleSelectors.isReadMoreButtonVisible() ? 'inline-block' : 'none',
    ticketMoreInfoButtonDisplay: styleSelectors.isTicketMoreInfoButtonVisible() ? 'block' : 'none',
    hiddenTicketDescriptionHeight: styleSelectors.isTicketMoreInfoButtonVisible() ? '0px' : 'auto',
    hiddenTicketDescriptionVisibility: styleSelectors.isTicketMoreInfoButtonVisible() ? 'hidden' : 'visible',
    hiddenTicketDescriptionMarginTop: styleSelectors.isTicketMoreInfoButtonVisible() ? '0' : '12px',
    aboutSectionWrapperVisibility: styleSelectors.isReadMoreButtonVisible() ? 'block' : 'none',
    aboutSectionWrapperMaxHeight: styleSelectors.isReadMoreButtonVisible() ? '14em' : 'auto',
    aboutSectionWrapperHeight: styleSelectors.isReadMoreButtonVisible() ? 'calc(14em - 36px)' : 'auto',
    eventImageVisibility: styleSelectors.isEventImageVisible() ? 'block' : 'none',
    imageLayoutVisible: styleSelectors.isEventImageVisible() ? 1 : 0,

    ...remapFont(
      'ticketPickerMainFont',
      isTicketPickerStyleList() ? 'ticketPickerListMainFontV2' : 'ticketPickerCardsMainFontV2',
    ),
    ...remapColor(
      'ticketPickerMainColor',
      isTicketPickerStyleList() ? 'ticketPickerListMainColor' : 'ticketPickerCardsMainColor',
    ),
    ...remapFont(
      'ticketPickerAdditionalFont',
      isTicketPickerStyleList() ? 'ticketPickerListAdditionalFontV2' : 'ticketPickerCardsAdditionalFontV2',
    ),
    ...remapColor(
      'ticketPickerAdditionalColor',
      isTicketPickerStyleList() ? 'ticketPickerListAdditionalColor' : 'ticketPickerCardsAdditionalColor',
    ),
    ticketPickerSpacing: isTicketPickerStyleList() ? '0' : 'var(--ticketPickerCardSpacing)',
    ticketPickerBorderCollapseWidth: getTicketPickerBorderCollapseWidth(),
    ticketPickerItemPadding: getTicketPickerPadding(),
    ticketPickerTopBorderWidth: isTicketPickerStyleList()
      ? 'calc(var(--ticketPickerListHorizontalDividerWidth) * 1px)'
      : 'calc(var(--ticketPickerCardsBorderWidth) * 1px)',
    ticketPickerBorderWidth: isTicketPickerStyleList()
      ? '0 0 calc(var(--ticketPickerListHorizontalDividerWidth) * 1px) 0'
      : 'calc(var(--ticketPickerCardsBorderWidth) * 1px)',
    ticketPickerInputBorderRadius: isTicketPickerStyleList()
      ? 'var(--ticketPickerListInputBorderRadius)'
      : 'var(--ticketPickerCardsInputBorderRadius)',
    ticketPickerBorderColor: isTicketPickerStyleList()
      ? 'var(--ticketPickerListHorizontalDividerColor)'
      : 'var(--ticketPickerCardsBorderColor)',
    ticketPickerVerticalDividerWidth: isTicketPickerStyleList()
      ? 'var(--ticketPickerListVerticalDividerWidth)'
      : 'var(--ticketPickerCardsVerticalDividerWidth)',
    ticketPickerVerticalDividerColor: isTicketPickerStyleList()
      ? 'var(--ticketPickerListVerticalDividerColor)'
      : 'var(--ticketPickerCardsVerticalDividerColor)',
    ...remapColor(
      'ticketPickerQuantityDropdownBgColor',
      isTicketPickerStyleList() ? 'evBackgroundColor' : 'ticketPickerCardsBackgroundColor',
    ),
    ticketPickerShadow: getTicketPickerShadow(),
    ...remapColor('ticketPickerBackgroundColor', isTicketPickerStyleList() ? null : 'ticketPickerCardsBackgroundColor'),
    ticketPickerBorderRadius: isTicketPickerStyleList() ? '0' : 'var(--ticketPickerCardsBorderRadius)',
    mobileTicketPickerContainerSidePadding: isTicketPickerStyleList() ? '0' : '20px',

    // Primary registration button - details page
    ...remapFont(
      'registrationButtonFont',
      isRegistrationButtonHollow() ? 'rsvpHollowButtonFontV2' : 'rsvpButtonFontV2',
    ),
    ...remapColor(
      'registrationButtonColor',
      isRegistrationButtonHollow() ? 'rsvpHollowButtonColor' : 'rsvpButtonColor',
    ),
    ...remapColor(
      'registrationButtonBackgroundColor',
      isRegistrationButtonHollow() ? null : 'rsvpButtonBackgroundColor',
    ),
    ...remapColor('registrationButtonBorderColor', isRegistrationButtonHollow() ? 'rsvpButtonBorderColor' : null),
    registrationButtonBorderWidth: isRegistrationButtonHollow() ? 'var(--rsvpButtonBorderWidth)' : '0',
    registrationButtonBorderRadius: isRegistrationButtonRounded()
      ? 'var(--rsvpButtonRoundedBorderRadius)'
      : 'var(--rsvpButtonBorderRadius)',

    // Secondary registration button - details page. Share some styles with primary, form/border
    ...remapFont(
      'secondaryRegistrationButtonFont',
      isRegistrationButtonHollow() ? 'secondRsvpHollowButtonFont' : 'secondRsvpButtonFont',
    ),
    ...remapColor(
      'secondaryRegistrationButtonColor',
      isRegistrationButtonHollow() ? 'secondRsvpHollowButtonColor' : 'secondRsvpButtonColor',
    ),
    ...remapColor(
      'secondaryRegistrationButtonBackgroundColor',
      isRegistrationButtonHollow() ? null : 'secondRsvpButtonBackgroundColor',
    ),
    ...remapColor(
      'secondaryRegistrationButtonBorderColor',
      isRegistrationButtonHollow() ? 'secondRsvpButtonBorderColor' : null,
    ),

    // Any other buttons (eg read more, Membership offers, etc) - details page
    ...remapColor(
      'additionalButtonColor',
      isRegistrationButtonHollow() ? 'secondRsvpButtonBorderColor' : 'secondRsvpButtonBackgroundColor',
    ),

    // Primary form button - form page
    ...remapFont('formButtonFont', isFormButtonHollow() ? 'formHollowButtonFontV2' : 'formFullButtonFontV2'),
    ...remapColor('formButtonColor', isFormButtonHollow() ? 'formHollowButtonColor' : 'formFullButtonColor'),
    ...remapColor('formButtonResolvedBorderColor', isFormButtonHollow() ? 'formButtonBorderColor' : null),
    formButtonResolvedBorderWidth: isFormButtonHollow() ? 'var(--formButtonBorderWidth)' : '0',
    formButtonResolvedBorderRadius: isFormButtonRounded()
      ? 'var(--formButtonRoundedBorderRadius)'
      : 'var(--formButtonBorderRadius)',
    ...remapColor('formButtonResolvedBackgroundColor', isFormButtonHollow() ? null : 'formButtonBackgroundColor'),

    // Other form buttons - form page
    ...remapColor(
      'formAdditionalButtonColor',
      isFormButtonHollow() ? 'formButtonBorderColor' : 'formButtonBackgroundColor',
    ),
  }
}
