import {ISettingsFont, ISiteTextPreset, IStyleParam, StyleParamType} from '@wix/tpa-settings'
import {GetDefaultValueProps, getDefaultFont} from '../../../../settings-commons/utils/style'

interface DefaultFontConfig {
  preset?: keyof ISiteTextPreset
  fontSize: number
  mobileFontSize: number
  staticMobileSize?: boolean
  staticDesktopSize?: boolean
}

interface GetV2FontDefaultParams {
  params: GetDefaultValueProps
  fontParam: IStyleParam<StyleParamType.Font>
  mobileSizeOverrideParam?: IStyleParam<StyleParamType.Number>
  defaults: DefaultFontConfig
}

export const getV2FontDefault = ({
  params,
  fontParam,
  mobileSizeOverrideParam,
  defaults: {preset = 'Body-M', fontSize, mobileFontSize, staticMobileSize, staticDesktopSize},
}: GetV2FontDefaultParams): ISettingsFont => {
  const {isMobile, getStyleParamValue} = params
  const font = getStyleParamValue(fontParam)
  const mobileSizeOverride = staticMobileSize ? mobileFontSize : getStyleParamValue(mobileSizeOverrideParam)

  if (font) {
    const {value: _value, preset: _preset, ...rest} = font
    return isMobile
      ? getDefaultFont(preset, {...rest, size: mobileSizeOverride ?? mobileFontSize})(params)
      : staticDesktopSize
      ? getDefaultFont(preset, {...rest, size: fontSize})(params)
      : font
  }

  return getDefaultFont(preset, {size: isMobile ? mobileSizeOverride ?? mobileFontSize : fontSize})(params)
}
