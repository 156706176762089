import {
  getExternalLink,
  hasImage,
  isExternal,
  isRegistrationClosedVisible,
  isRegistrationScheduled,
  isRegistrationWithCustomDates,
  isTicketed,
  isWithoutRegistration,
  saleScheduledAndHasTickets,
  ticketedOrInitiallyTicketed,
} from '@wix/wix-events-commons-statics'
import {withTranslation} from '@wix/yoshi-flow-editor'
import {getEventListPageUrl} from '../../../../../../commons/selectors/environment'
import {getEvent} from '../../../selectors/event'
import {isMemberRsvpExists} from '../../../selectors/member-rsvp'
import {getTickets} from '../../../selectors/tickets'
import {DetailsPageAppProps} from '../../app/interfaces'
import {connect} from '../../runtime-context/context'
import {RegistrationButtonOwnProps, RegistrationButtonRuntimeProps} from './interfaces'
import {RegistrationButton as Presentation} from './registration-button'

const mapRuntime = ({state}: DetailsPageAppProps): RegistrationButtonRuntimeProps => {
  const event = getEvent(state)

  return {
    registrationClosed: isRegistrationClosedVisible(event),
    withoutRegistration: isWithoutRegistration(event),
    memberRsvpExists: isMemberRsvpExists(state),
    ticketedOrInitiallyTicketedEvent: ticketedOrInitiallyTicketed(event),
    imageVisible: hasImage(event),
    ticketsUnavailable:
      isTicketed(event) && (!getTickets(state).length || getTickets(state).every(saleScheduledAndHasTickets)),
    url: getExternalLink(event),
    isExternalLink: isExternal(event),
    registrationScheduled: isRegistrationScheduled(event),
    registrationWithCustomDates: isRegistrationWithCustomDates(event),
    eventId: event.id,
    eventListPageUrl: getEventListPageUrl(state),
  }
}

export const RegistrationButton = withTranslation()(
  connect<RegistrationButtonOwnProps, RegistrationButtonRuntimeProps>(mapRuntime)(Presentation),
)
