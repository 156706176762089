import {useSlotPlaceholder} from '@wix/widget-plugins-ooi'
import {ExperimentNames, hasImage} from '@wix/wix-events-commons-statics'
import {detailsPageDataHooks as DH} from '@wix/wix-events-data-hooks'
import {useEnvironment, useExperiments} from '@wix/yoshi-flow-editor'
import classNames from 'classnames'
import React from 'react'
import {SLOT_EVENT_DETAILS_HEADER} from '../../../../../../../commons/constants/slots'
import {useFullWidth} from '../../../../hooks/full-width'
import {useAppSelector} from '../../../../hooks/runtime'
import {getEvent} from '../../../../selectors/event'
import alignmentClasses from '../../alignment/alignment.scss'
import {RegistrationButton} from '../../registration-button'
import {Description} from '../description'
import {ShortDateLocation} from '../short-date-location'
import {Title} from '../title'
import {Image} from './image/image'
import s from './side-by-side.scss'

interface SideBySideLayoutProps {
  onClickRegistrationButton: Function
}

export const SideBySideLayout: React.FC<SideBySideLayoutProps> = ({onClickRegistrationButton}) => {
  const {experiments} = useExperiments()
  const {isEditorX: responsive} = useEnvironment()
  const slotsEnabled = experiments.enabled(ExperimentNames.EventDetailsSlots)
  const imageVisible = hasImage(useAppSelector(getEvent))

  const [SlotPlaceholder, slotEmpty] = useSlotPlaceholder(SLOT_EVENT_DETAILS_HEADER)
  const fullWidth = useFullWidth()

  return (
    <div className={classNames(s.wrapper)}>
      <div className={classNames(s.container, {[s.fullWidth]: fullWidth})} data-hook={DH.header}>
        {imageVisible ? <Image /> : null}
        <div
          className={classNames(
            s.eventInfoContainer,
            imageVisible ? s.withImage : s.withoutImage,
            fullWidth || responsive ? s.fullWidth : s.minimized,
            s.content,
            alignmentClasses.headerAlignment,
          )}
        >
          <ShortDateLocation />
          <Title />
          {slotsEnabled && !slotEmpty ? (
            <div className={s.slotHeaderContainer}>
              <SlotPlaceholder />
            </div>
          ) : null}
          <Description />
          <RegistrationButton onClick={onClickRegistrationButton} className={s.sideBySideRegistrationButton} />
        </div>
      </div>
    </div>
  )
}
