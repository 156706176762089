import {getEvent} from '../../../selectors/event'
import {DetailsPageAppProps} from '../../app/interfaces'
import {connect} from '../../runtime-context/context'
import {FullDate as FullDatePresentation, FullDateProps} from './full-date'

const mapRuntime = ({state}: DetailsPageAppProps): FullDateProps => ({
  event: getEvent(state),
})

export const FullDate = connect<{}, FullDateProps>(mapRuntime)(FullDatePresentation)
