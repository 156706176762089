import {detailsPageDataHooks as DH} from '@wix/wix-events-data-hooks'
import {Check} from '@wix/wix-ui-icons-common'
import {useTranslation} from '@wix/yoshi-flow-editor'
import classNames from 'classnames'
import React from 'react'
import {getPlanBenefitText, planVisible} from '../../../../selectors/paid-plans'
import c from '../../../classnames.scss'
import s from '../membership-offers.scss'
import {PlansProps} from './interfaces'

export const Plans = ({plans, translatedDetails}: PlansProps) => {
  const {t} = useTranslation()

  const getAdditionalFees = (planId: string): string => {
    const additionalFees = translatedDetails[planId]?.additionalFees
    if (!additionalFees || additionalFees.length === 0) {
      return ''
    }

    return ' + ' + additionalFees.map(fee => `${fee.amount} ${fee.name}`).join(', ')
  }

  return (
    <>
      {plans.map(
        plan =>
          planVisible(plan) && (
            <div
              className={classNames(s.membershipItem, c.membershipOffersBorderColor, s.textFont)}
              data-hook={`${DH.MEMBERSHIP_OFFERS_PLAN}-${plan.id}`}
              key={plan.id}
            >
              <div className={classNames(s.content, s.textFont)}>
                <div className={s.primary} data-hook={DH.MEMBERSHIP_OFFERS_PLAN_NAME}>
                  {plan.name}
                </div>
                <div className={s.secondary} data-hook={DH.MEMBERSHIP_OFFERS_PLAN_BENEFIT}>
                  {getPlanBenefitText(plan, t)}
                </div>
              </div>
              <div className={s.pricing}>
                <div
                  className={classNames(s.primary, {[s.withIcon]: plan.purchased})}
                  data-hook={DH.MEMBERSHIP_OFFERS_PLAN_PRICING}
                >
                  {plan.purchased ? (
                    <>
                      <Check className={s.purchasedIcon} />
                      {t('membership_purchased')}
                    </>
                  ) : (
                    [translatedDetails[plan.id]?.price, translatedDetails[plan.id]?.cycle].filter(Boolean).join(' / ') +
                    getAdditionalFees(plan.id)
                  )}
                </div>
                <div className={s.secondary} data-hook={DH.MEMBERSHIP_OFFERS_PLAN_INFO}>
                  {[translatedDetails[plan.id]?.duration, translatedDetails[plan.id]?.freeTrial]
                    .filter(Boolean)
                    .join(' ')}
                </div>
              </div>
            </div>
          ),
      )}
    </>
  )
}
