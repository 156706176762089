import classNames from 'classnames'
import React from 'react'
import {TagsAlignment, TagsSize, TagsSkin, Tags as TagsTPA} from 'wix-ui-tpa/cssVars'
import {TagsProps} from './interfaces'
import {classes} from './tags.st.css'

export const Tags: React.FC<TagsProps> = ({className, items}) => (
  <TagsTPA
    className={classNames(classes.tags, className)}
    alignment={TagsAlignment.left}
    skin={TagsSkin.light}
    size={TagsSize.small}
    onClick={() => null}
    items={items}
  />
)
