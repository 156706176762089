import {HEADER_LAYOUT} from '@wix/wix-events-commons-statics'
import type {PageComponentSettings} from '../../stylesParams'
import {State} from '../types/state'
import {hasScheduleItems} from './schedule'

export const MOBILE_SETTING_POSTFIX = '▶︎m'

export const isScheduleVisible = (state: State) => hasScheduleItems(state)

export const getHeaderLayout = (settings: PageComponentSettings) => settings.headerLayout

export const isSideBySideHeaderLayout = (settings: PageComponentSettings) =>
  settings.headerLayout === HEADER_LAYOUT.SIDE_BY_SIDE
