import {detailsPageDataHooks as DH} from '@wix/wix-events-data-hooks'
import {useEnvironment, useTranslation} from '@wix/yoshi-flow-editor'
import classNames from 'classnames'
import React from 'react'
import {useAppSelector} from '../../../hooks/runtime'
import {getUpdatesCount} from '../../../selectors/groups'
import cs from '../../classnames.scss'
import s from './group-activity.scss'

export const GroupActivity = ({className}: {className: string}) => {
  const {isMobile} = useEnvironment()
  const {t} = useTranslation()
  const updatesCount = useAppSelector(getUpdatesCount)

  return (
    <div
      className={classNames(
        s.root,
        cs.textFontAndColor,
        {
          [s.mobileTextSize]: isMobile,
          [s.fixedTextSize]: !isMobile,
        },
        className,
      )}
      data-hook={DH.GROUPS_ACTIVITY}
    >
      <div className={s.text}>{t('groups.activity.explanation')}</div>
      {updatesCount !== 0 ? (
        <div
          className={classNames(s.activity, {
            [s.mobileTextSize]: isMobile,
            [s.fixedActivityTextSize]: !isMobile,
          })}
        >
          {updatesCount === 1
            ? t('groups.activity.singular')
            : t('groups.activity.plural', {updatesCount: updatesCount ?? '-'})}
        </div>
      ) : null}
    </div>
  )
}
