import {SERVER_BASE_URL} from '@wix/wix-events-commons-statics'
import {detailsPageDataHooks as DH} from '@wix/wix-events-data-hooks'
import {useEnvironment, useTranslation} from '@wix/yoshi-flow-editor'
import classNames from 'classnames'
import React, {useEffect, useState} from 'react'
import {useAppActions, useAppSelector} from '../../../hooks/runtime'
import {useVisibilityStyles} from '../../../hooks/use-visibility-styles'
import {googleMapPolicyWarningVisible} from '../../../selectors/consent-policy'
import {getStaticMapUrl as getStaticMapSelector} from '../../../selectors/static-map-url'
import sc from '../../classnames.scss'
import {MapProps} from './interfaces'
import s from './map.scss'

export const Map = ({coordinates, language, instance}: MapProps) => {
  const {
    allBreakpoints: {isMapVisible},
  } = useVisibilityStyles()
  const {t} = useTranslation()
  const {isMobile: mobile} = useEnvironment()
  const staticMapUrl = useAppSelector(getStaticMapSelector)
  const policyWarningVisible = useAppSelector(googleMapPolicyWarningVisible)
  const showStaticMap = mobile
  const {getStaticMapUrl} = useAppActions()

  const [shouldMapLoad, setShouldMapLoad] = useState(false)

  useEffect(() => {
    if (showStaticMap) {
      getStaticMapUrl(language)
    }
    setShouldMapLoad(true)
  }, [])

  if (!coordinates || !isMapVisible()) {
    return null
  }

  if (showStaticMap) {
    return (
      <a
        href={`https://www.google.com/maps/search/?api=1&query=${coordinates.lat},${coordinates.lng}`}
        rel="noopener noreferrer"
        target="_blank"
      >
        <img
          id="event-details-page-map"
          data-hook={DH.MAP}
          src={staticMapUrl}
          className={s.staticMap}
          alt={t('eventLocationMap')}
          height={360}
          width={640}
        />
      </a>
    )
  }

  if (policyWarningVisible) {
    return <span className={classNames(sc.evTextFont, sc.evTextColor)}>{t('googleMaps_consentPolicy_blocked')}</span>
  }

  return (
    <div id="event-details-page-map" className={s.map} data-hook={DH.MAP}>
      {shouldMapLoad && (
        <iframe
          src={`${SERVER_BASE_URL}/html/google-map?locale=${language}&lat=${coordinates.lat}&lng=${coordinates.lng}&instance=${instance}`}
          className={s.iframe}
          allowFullScreen
          title="Map"
          loading="lazy"
        />
      )}
    </div>
  )
}
