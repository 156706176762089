import {isActive} from '@wix/wix-events-commons-statics'
import {detailsPageDataHooks as DH} from '@wix/wix-events-data-hooks'
import {useTranslation} from '@wix/yoshi-flow-editor'
import classNames from 'classnames'
import React from 'react'
import {useAppActions, useAppSelector} from '../../../hooks/runtime'
import {getEvent} from '../../../selectors/event'
import {SectionButton} from '../section-button'
import s from './recurring-dates-button.scss'

interface RecurringDatesButtonProps {
  className?: string
  narrowerButton?: boolean
}

export const RecurringDatesButton = ({className, narrowerButton}: RecurringDatesButtonProps) => {
  const {t} = useTranslation()
  const {openRecurringEventsModal} = useAppActions()
  const event = useAppSelector(getEvent)

  return (
    <SectionButton
      onClick={() => openRecurringEventsModal(event.id)}
      className={classNames(s.root, className)}
      dataHook={DH.RECURRING_DATES_BUTTON}
      narrowerButton={narrowerButton}
    >
      {isActive(event) ? t('recurring.selectDatesButton') : t('recurring.selectUpcomingDatesButton')}
    </SectionButton>
  )
}
