import {withSlotsPlaceholders} from '@wix/widget-plugins-ooi'
import {viewerDataHooks as DH} from '@wix/wix-events-data-hooks'
import {useEnvironment} from '@wix/yoshi-flow-editor'
import classNames from 'classnames'
import React, {useEffect} from 'react'
import {TPAComponentsProvider} from 'wix-ui-tpa/cssVars'
import {ErrorPage} from '../../../../../commons/components/error-page'
import {FocusHandler} from '../../../../../commons/components/focus-handler'
import {Direction} from '../../../../../commons/constants/html'
import {DatesProvider} from '../../../../../commons/hooks/dates'
import {StylesForAllBreakpointsProvider} from '../../../../../commons/hooks/styles-for-all-breakpoints'
import {ROOT_COMPONENT_ID} from '../../constants'
import {useMobileSize} from '../../hooks/full-width'
import {MOBILE_SETTING_POSTFIX} from '../../selectors/settings'
import {Modals} from '../modals'
import {Router} from '../router'
import {DetailsPageRuntimeContext} from '../runtime-context/context'
import s from './app.scss'
import {DetailsPageAppProps} from './interfaces'

const DetailsPage: React.FC<DetailsPageAppProps> = props => {
  const {isRTL, isMobile, isEditorX: responsive} = useEnvironment()
  const mobileSize = useMobileSize()
  const mobile = isMobile || mobileSize
  const {state} = props

  useEffect(() => {
    if (state?.navigation?.shouldNavigateBack) {
      window.history.back()
    }
  }, [state])

  useEffect(() => {
    // Cheap way to inform Sled about end of hydration
    document.getElementById(ROOT_COMPONENT_ID)?.setAttribute('data-hydrated', 'true')
  }, [])

  if (!state) {
    return <ErrorPage />
  }

  mergeSettings(props, mobile)

  return (
    <DetailsPageRuntimeContext.Provider value={props}>
      <TPAComponentsProvider value={{mobile, rtl: isRTL}}>
        <DatesProvider dates={state.dates}>
          <StylesForAllBreakpointsProvider>
            <div
              dir={isRTL ? Direction.RTL : Direction.LTR}
              id={ROOT_COMPONENT_ID}
              className={s.root}
              data-hook={DH.ROOT_NODE}
            >
              <Modals />
              <FocusHandler />
              <div
                className={classNames(s.root, {
                  'events-details-page-mobile': mobile,
                  'events-details-page-desktop': !mobile,
                  'events-details-page-responsive': responsive,
                  'events-details-page-nonresponsive': !responsive,
                })}
              >
                <Router />
              </div>
            </div>
          </StylesForAllBreakpointsProvider>
        </DatesProvider>
      </TPAComponentsProvider>
    </DetailsPageRuntimeContext.Provider>
  )
}

export const App = withSlotsPlaceholders(DetailsPage)

const mergeSettings = (props: DetailsPageAppProps, mobile: boolean) => {
  const sources = [props.host.style.styleParams.numbers, props.host.style.styleParams.booleans]

  for (const source of sources) {
    props.state.component.settings = {
      ...props.state.component.settings,
      ...source,
    }
  }

  if (mobile) {
    for (const source of sources) {
      Object.entries(source).forEach(([key, value]) => {
        if (key.endsWith(MOBILE_SETTING_POSTFIX)) {
          props.state.component.settings[key.substring(0, key.length - MOBILE_SETTING_POSTFIX.length)] = value
        }
      })
    }
  }
}
