import {getHeaderLayout} from '../../../selectors/settings'
import {DetailsPageAppProps} from '../../app/interfaces'
import {connect} from '../../runtime-context/context'
import {Header as HeaderPresentation} from './header'
import {HeaderOwnProps, HeaderRuntimeProps} from './interfaces'

const mapRuntime = ({state}: DetailsPageAppProps): HeaderRuntimeProps => ({
  headerLayout: getHeaderLayout(state.component.settings),
})

export const Header = connect<HeaderOwnProps, HeaderRuntimeProps>(mapRuntime)(HeaderPresentation)
