import {focusElement, hookToAttributeSelector} from '@wix/panda-js-utils'
import {useSlotPlaceholder} from '@wix/widget-plugins-ooi'
import {BI_ORIGINS, ExperimentNames} from '@wix/wix-events-commons-statics'
import {detailsPageDataHooks as DH} from '@wix/wix-events-data-hooks'
import {useExperiments} from '@wix/yoshi-flow-editor'
import classNames from 'classnames'
import React, {useEffect} from 'react'
import {SLOT_EVENT_DETAILS_CONTENT, SLOT_EVENT_DETAILS_HEADER} from '../../../../../commons/constants/slots'
import {useSettings} from '../../../hooks/settings'
import {settingsParams} from '../../../settingsParams'
import {useAppSelector} from '../../hooks/runtime'
import {useVisibilityStyles} from '../../hooks/use-visibility-styles'
import {isPaidPlansVisible} from '../../selectors/paid-plans'
import {FormEntry} from '../chunks/form-entry'
import {SeatingPlanModal} from '../chunks/seating-plan-modal'
import c from '../classnames.scss'
import {AboutSection} from '../event-details/about-section'
import alignmentClasses from '../event-details/alignment/alignment.scss'
import {FullDate} from '../event-details/full-date'
import {FullLocation} from '../event-details/full-location'
import {GroupActivity} from '../event-details/group-activity/group-activity'
import {ShortDateLocation} from '../event-details/header/short-date-location'
import {Map} from '../event-details/map'
import {MembershipOffers} from '../event-details/membership-offers'
import {RecurringDatesButton} from '../event-details/recurring-dates-button'
import {RecurringDatesCount} from '../event-details/recurring-dates-count'
import {RegistrationButton} from '../event-details/registration-button'
import {SecondaryRsvpButton} from '../event-details/secondary-rsvp-button'
import {MobileTicketsPicker} from '../event-details/tickets-picker-new/lazy-tickets-picker'
import {Members} from '../members/members'
import {SocialShareWithTitle} from '../social-share/with-title/with-title'
import {Subtitle} from '../subtitle/subtitle'
import {Description} from './description'
import s from './event-details-mobile.scss'
import {ContainerProps, EventDetailsProps} from './interfaces'
import {MobileImage} from './mobile-image'
import {MobileSchedule} from './mobile-schedule/mobile-schedule'

export const EventDetailsMobile = ({
  eventTitle,
  membersVisible,
  t,
  mapVisible,
  aboutSectionVisible,
  scheduleVisible,
  sideBySideHeaderLayout,
  detailsPageLoaded,
  handleRSVP,
  mainImage,
  eventDescription,
  moreDatesVisible,
  moreDatesButtonVisible,
  groupVisible,
  hasTickets,
  ticketedEvent,
  withSeatingPlan,
  openSeatingPlan,
  scrollToElement,
}: EventDetailsProps) => {
  const {experiments} = useExperiments()
  const paidPlansVisible = useAppSelector(isPaidPlansVisible)
  const slotsEnabled = experiments.enabled(ExperimentNames.EventDetailsSlots)

  const [HeaderSlotPlaceholder, headerSlotEmpty] = useSlotPlaceholder(SLOT_EVENT_DETAILS_HEADER)
  const [ContentSlotPlaceholder, contentSlotEmpty] = useSlotPlaceholder(SLOT_EVENT_DETAILS_CONTENT)

  const {
    allBreakpoints: {isMembersVisible, isGroupVisible, isScheduleVisible, isMembershipsVisible, isEventImageVisible},
  } = useVisibilityStyles()

  useEffect(() => {
    detailsPageLoaded()
    focusElement({selector: hookToAttributeSelector(DH.eventDetails), preventScroll: true})
  }, [])

  const isImageVisible = Boolean(mainImage) && isEventImageVisible()

  const handleRegistrationButtonClick = () => {
    if (ticketedEvent) {
      if (withSeatingPlan) {
        SeatingPlanModal.preload()
        return openSeatingPlan()
      } else {
        return scrollToElement(DH.TICKETS_PICKER)
      }
    } else {
      FormEntry.preload()
      return handleRSVP()
    }
  }

  const settings = useSettings()
  const timeAndLocationTitleText = settings.get(settingsParams.timeAndLocationTitleText)
  const guestsTitleText = settings.get(settingsParams.guestsTitleText)
  const scheduleTitleText = settings.get(settingsParams.scheduleTitleText)

  return (
    <section data-hook={DH.eventDetails} className={s.view} aria-label={eventTitle}>
      <div className={classNames(s.background, c.evBackgroundColor)} />
      <div className={classNames({[s.borderWrapper]: sideBySideHeaderLayout})}>
        <div className={s.eventImage}>{isImageVisible ? <MobileImage /> : null}</div>
        <div className={s.header}>
          <div className={s.headerBackground} />
          <div className={s.content}>
            <div className={alignmentClasses.headerAlignment}>
              <h1 className={classNames(s.title, c.evTitleFont, c.evTitleColor)} data-hook={DH.headerEventTitle}>
                {eventTitle}
              </h1>
              <ShortDateLocation mobile />
              {slotsEnabled && !headerSlotEmpty ? (
                <div className={s.slotHeaderContainer}>
                  <HeaderSlotPlaceholder />
                </div>
              ) : null}
              <Description description={eventDescription} />
            </div>
          </div>
          <RegistrationButton
            className={classNames(s.registrationButton, alignmentClasses.headerAlignment)}
            onClick={handleRegistrationButtonClick}
            disableSpacing
            narrowerButton
          />
        </div>
      </div>
      <Container visible dataHook="dateLocationContainer">
        <Subtitle className={s.eventInfoTitle} dataHook={DH.subtitleTimeAndLocation} text={timeAndLocationTitleText} />
        <div className={!moreDatesButtonVisible ? s.dateLocationWithoutDatesButton : null}>
          <div className={classNames(s.timeAndLocation, c.textFontAndColor)}>
            <FullDate />
            <FullLocation />
          </div>
          {moreDatesVisible && <RecurringDatesCount className={s.recurrentDatesCount} disableSpacing />}
        </div>
      </Container>
      <Container visible={moreDatesButtonVisible} dataHook="recurringDatesButtonContainer">
        <RecurringDatesButton className={classNames(s.recurrentDatesButton)} narrowerButton />
      </Container>
      {isMembersVisible() ? (
        <Container className={s.membersContainer} visible={membersVisible} dataHook="membersContainer">
          <Subtitle className={s.eventInfoTitle} dataHook={DH.subtitleMembers} text={guestsTitleText} />
          <div className={s.members}>
            <Members />
          </div>
        </Container>
      ) : null}
      <Container className={s.aboutSectionContainer} visible={aboutSectionVisible} dataHook="aboutSectionContainer">
        <AboutSection />
      </Container>
      {isGroupVisible() ? (
        <Container className={s.groupContainer} visible={groupVisible} dataHook="groupsContainer">
          <GroupActivity className={s.groupActivity} />
        </Container>
      ) : null}
      {isScheduleVisible() ? (
        <Container className={s.scheduleContainer} visible={scheduleVisible} dataHook="scheduleContainer">
          <Subtitle className={s.eventInfoTitle} dataHook={DH.SCHEDULE_SUBTITLE} text={scheduleTitleText} />
          <MobileSchedule />
        </Container>
      ) : null}
      {slotsEnabled && !contentSlotEmpty ? (
        <div className={s.slotDetailsContainer}>
          <ContentSlotPlaceholder />
        </div>
      ) : null}
      <Container visible={hasTickets} dataHook="ticketPickerContainer" className={s.ticketPickerContainer}>
        {paidPlansVisible && isMembershipsVisible() ? (
          <div className={s.membershipOffers}>
            <MembershipOffers upgrade />
          </div>
        ) : null}
        <MobileTicketsPicker />
      </Container>
      <SecondaryRsvpButton onClick={handleRegistrationButtonClick} narrowerButton />
      {mapVisible && (
        <div className={s.mapSpacing}>
          <Map />
        </div>
      )}
      <Container className={s.socialShare} visible={true} dataHook="socialShareContainer">
        <SocialShareWithTitle page={BI_ORIGINS.EVENT_DETAILS} t={t} />
      </Container>
    </section>
  )
}

const Container = ({visible, children, dataHook, className}: ContainerProps) =>
  visible ? (
    <div className={classNames(s.content, alignmentClasses.contentAlignment, className)} data-hook={dataHook}>
      {children}
    </div>
  ) : (
    <div />
  )
