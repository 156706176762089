import {isActive} from '@wix/wix-events-commons-statics'
import {State} from '../types/state'
import {getEvent} from './event'

export const isMoreDatesLabelVisible = (state: State) => !!state.upcomingOccurrencesCount

export const isLastAvailableOccurrence = (state: State) =>
  isActive(getEvent(state)) && state.upcomingOccurrencesCount === 1

export const getMoreOccurrencesCount = (state: State) =>
  isActive(getEvent(state)) ? state.upcomingOccurrencesCount - 1 : state.upcomingOccurrencesCount

export const isMoreDatesButtonVisible = (state: State) => getMoreOccurrencesCount(state) > 0
