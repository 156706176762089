import {truncateRichContent} from '@wix/ricos'
import {useStyles} from '@wix/tpa-settings/react'
import {ExperimentNames, getAboutText} from '@wix/wix-events-commons-statics'
import {detailsPageDataHooks as DH} from '@wix/wix-events-data-hooks'
import {useEnvironment, useExperiments, useTranslation} from '@wix/yoshi-flow-editor'
import classNames from 'classnames'
import {isEqual} from 'lodash'
import React, {Suspense, useEffect, useMemo, useState} from 'react'
import xss from 'xss'
import {getElementStyle} from '../../../../../../commons/utils/dom'
import {useSettings} from '../../../../hooks/settings'
import {settingsParams} from '../../../../settingsParams'
import stylesParams from '../../../../stylesParams'
import {useAppSelector} from '../../../hooks/runtime'
import {useVisibilityStyles} from '../../../hooks/use-visibility-styles'
import {getEvent, getEventRichContent} from '../../../selectors/event'
import {isGroupActivityVisible} from '../../../selectors/groups'
import {isPaidPlansVisible} from '../../../selectors/paid-plans'
import {RicosViewer} from '../../chunks/ricos-viewer'
import cl from '../../classnames.scss'
import {Subtitle} from '../../subtitle/subtitle'
import s from './about-section.scss'
import {AboutSectionProps} from './interfaces'
import {ReadMoreButton} from './read-more-button'

const WRAPPER_ID = 'events-details-page-about-wrapper'
const INNER_ID = 'events-details-page-about-inner'

export const AboutSection = ({compact}: AboutSectionProps) => {
  const {isMobile, isEditor} = useEnvironment()
  const {experiments} = useExperiments()
  const {t} = useTranslation()
  const {
    allBreakpoints: {isAboutSectionVisible},
  } = useVisibilityStyles()

  const readMoreButtonVisible = useStyles().get(stylesParams.readMoreButtonVisible)

  const paidPlansVisible = useAppSelector(state => isPaidPlansVisible(state) && !isGroupActivityVisible(state))
  const eventId = useAppSelector(state => getEvent(state).id)
  const aboutText = useAppSelector(state => getAboutText(getEvent(state)))
  const updateRichContentTruncateEnabled = experiments.enabled(ExperimentNames.UpdateRichContentTruncate)
  const richContent = useAppSelector(state =>
    getEventRichContent(
      state,
      !experiments.enabled(ExperimentNames.DisableRicos),
      experiments.enabled(ExperimentNames.UseRichContentFromEvent),
    ),
  )
  const settings = useSettings()
  const aboutTitleText = settings.get(settingsParams.aboutTitleText)
  const [collapsed, setCollapsed] = useState(true)
  const [buttonVisible, setButtonVisible] = useState(false)

  const truncatedRichContent = useMemo(() => {
    if (richContent) {
      const content = truncateRichContent(richContent, {
        wordCount: 150,
        nodeCount: 6,
        ...(updateRichContentTruncateEnabled ? {} : {maxPlugins: 2}),
      })

      return {
        content,
        truncated: !isEqual(content, richContent),
      }
    }
  }, [richContent])

  useEffect(
    () => {
      const wrapper = document.getElementById(WRAPPER_ID)
      const inner = document.getElementById(INNER_ID)

      if (wrapper && inner) {
        setTimeout(() => {
          // todo remove hack and find proper solution
          const wrapperHeight = parseInt(getElementStyle(wrapper, 'height'), 10)
          const innerHeight = parseInt(getElementStyle(inner, 'height'), 10)

          setButtonVisible(innerHeight > wrapperHeight)
        }, 100)
      }
    },
    isEditor ? undefined : [],
  )

  if (!isAboutSectionVisible()) {
    return null
  }

  if (truncatedRichContent) {
    return (
      <div
        data-hook={DH.ABOUT_SECTION}
        className={classNames(s.ricosContainer, {
          [s.ricosMobileContainer]: isMobile,
          [s.largerBottomMargin]: paidPlansVisible,
        })}
      >
        <Subtitle
          dataHook={DH.ABOUT}
          className={classNames({
            [s.mobileSubtitle]: isMobile,
          })}
          text={aboutTitleText}
          compact={compact}
        />
        <Suspense fallback={null}>
          <RicosViewer content={collapsed && readMoreButtonVisible ? truncatedRichContent.content : richContent} />
        </Suspense>
        <ReadMoreButton
          visible={truncatedRichContent.truncated}
          collapsed={collapsed}
          onClick={() => setCollapsed(!collapsed)}
        />
      </div>
    )
  }

  return (
    <div
      className={classNames(s.container, {
        [s.mobileContainer]: isMobile,
        [s.largerBottomMargin]: paidPlansVisible,
      })}
      data-hook={DH.ABOUT_SECTION}
    >
      <Subtitle
        className={classNames({
          [s.mobileSubtitle]: isMobile,
        })}
        dataHook={DH.ABOUT}
        text={aboutTitleText}
        compact={compact}
      />
      <div
        id={WRAPPER_ID}
        data-hook={DH.ABOUT_SECTION_WRAPPER}
        className={classNames(s.wrapper, cl.textFontAndColor, {
          [s.expanded]: !collapsed,
          [s.wrapperNoShadow]: !buttonVisible,
          [s.buttonVisible]: buttonVisible,
        })}
      >
        <div
          id={INNER_ID}
          className={s.aboutText}
          data-hook={DH.ABOUT_SECTION_TEXT}
          key={eventId}
          dangerouslySetInnerHTML={{
            __html: xss(isEditor && !aboutText ? t('demoEvent_aboutSectionText') : aboutText),
          }}
        />
      </div>
      <ReadMoreButton visible={buttonVisible} collapsed={collapsed} onClick={() => setCollapsed(!collapsed)} />
    </div>
  )
}
