import classNames from 'classnames'
import React, {PropsWithChildren} from 'react'
import {Button, ButtonPriority} from 'wix-ui-tpa/cssVars'
import {SecondaryButtonProps} from './interfaces'
import {classes} from './secondary-button.st.css'

export const SecondaryButton = ({
  onClick,
  className,
  dataHook,
  narrowerButton,
  children,
}: PropsWithChildren<SecondaryButtonProps>) => (
  <Button
    upgrade
    priority={ButtonPriority.basicSecondary}
    className={classNames(classes.root, className, {
      [classes.narrowerButton]: narrowerButton,
    })}
    onClick={onClick}
    data-hook={dataHook}
  >
    {children}
  </Button>
)
