import {useEnvironment} from '@wix/yoshi-flow-editor'
import React, {PropsWithChildren} from 'react'
import {SecondaryButton} from '../../styled-components/secondary-button'
import {SectionButtonProps} from './interfaces'

export const SectionButton = ({
  onClick,
  dataHook,
  className,
  narrowerButton,
  children,
}: PropsWithChildren<SectionButtonProps>) => {
  const {isMobile: mobile} = useEnvironment()

  return (
    <SecondaryButton
      className={className}
      mobile={mobile}
      onClick={onClick}
      dataHook={dataHook}
      narrowerButton={narrowerButton}
    >
      {children}
    </SecondaryButton>
  )
}
