import {detailsPageDataHooks as DH} from '@wix/wix-events-data-hooks'
import {useTranslation} from '@wix/yoshi-flow-editor'
import classNames from 'classnames'
import React from 'react'
import {useAppSelector} from '../../../hooks/runtime'
import {getMoreOccurrencesCount, isLastAvailableOccurrence} from '../../../selectors/occurrences'
import cs from '../../classnames.scss'
import {RecurringDatesCountProps} from './interfaces'
import s from './recurring-dates-count.scss'

export const RecurringDatesCount = ({disableSpacing, className}: RecurringDatesCountProps) => {
  const count = useAppSelector(getMoreOccurrencesCount)
  const isLastOccurrence = useAppSelector(isLastAvailableOccurrence)
  const {t} = useTranslation()

  return (
    <div
      data-hook={DH.eventDetailsFullLocation}
      className={classNames({[s.spacing]: !disableSpacing}, cs.textFontAndColor, className)}
    >
      {isLastOccurrence ? t('recurring.lastAvailableDate') : t('recurring.moreDatesLabel', {count})}
    </div>
  )
}
