import {getCoordinates} from '@wix/wix-events-commons-statics'
import {getLanguage} from '../../../../../../commons/selectors/environment'
import {getEvent} from '../../../selectors/event'
import {DetailsPageAppProps} from '../../app/interfaces'
import {connect} from '../../runtime-context/context'
import {MapRuntimeProps} from './interfaces'
import {Map as Presentation} from './map'

const mapRuntime = ({state}: DetailsPageAppProps): MapRuntimeProps => ({
  coordinates: getCoordinates(getEvent(state)),
  language: getLanguage(state),
  instance: state.instance.instance,
})

export const Map = connect<{}, MapRuntimeProps>(mapRuntime)(Presentation)
