import {detailsPageDataHooks as DH} from '@wix/wix-events-data-hooks'
import {useEnvironment} from '@wix/yoshi-flow-editor'
import classNames from 'classnames'
import React from 'react'
import {useAppActions} from '../../../hooks/runtime'
import s from './rsvp-info-button.scss'

export interface RsvpInfoButtonProps {
  messages: wix.events.form.RegistrationClosedMessages
  dataHook: string
  href?: string
  onClick?: () => void
}

export const RsvpInfoButton = ({messages, dataHook, href, onClick}: RsvpInfoButtonProps) => {
  const {isPreview, isMobile: mobile} = useEnvironment()
  const {navigateToMainPage} = useAppActions()

  const handleOnClick = (e: React.MouseEvent) => {
    e.preventDefault()

    if (isPreview && !onClick && !href) {
      navigateToMainPage()
    } else {
      onClick()
    }
  }

  return (
    <div className={classNames(s.container, mobile ? s.mobileContainer : '')} data-hook={dataHook}>
      <div>{messages.message}</div>
      {href ? (
        <a className={s.link} data-hook={DH.RSVP_INFO_BUTTON} href={href}>
          {messages.exploreEventsActionLabel}
        </a>
      ) : (
        <button
          className={s.link}
          data-hook={DH.RSVP_INFO_BUTTON}
          onClick={isPreview || onClick ? handleOnClick : null}
        >
          {messages.exploreEventsActionLabel}
        </button>
      )}
    </div>
  )
}
