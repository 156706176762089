import classNames from 'classnames'
import React from 'react'
import {useFullWidth} from '../../../../../hooks/full-width'
import {EventImage} from '../../../../event-image'
import s from './image.scss'

export const Image = () => {
  const fullWidth = useFullWidth()

  return <EventImage className={classNames(s.imageContainer, {[s.minimized]: !fullWidth})} />
}
