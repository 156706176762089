import {detailsPageDataHooks as DH} from '@wix/wix-events-data-hooks'
import classNames from 'classnames'
import React from 'react'
import {useEventDateInformation} from '../../../../../../commons/hooks/dates'
import {useSettings} from '../../../../hooks/settings'
import {settingsParams} from '../../../../settingsParams'
import {useAppSelector} from '../../../hooks/runtime'
import {isSecondRegistrationButtonVisible} from '../../../selectors/event'
import {RegistrationButtonSkin} from '../../registration-buttons/registration-button-skin'
import {CustomDatesLabel} from '../custom-dates-label'
import {SecondaryRsvpButtonProps} from './interfaces'
import s from './secondary-rsvp-button.scss'

export const SecondaryRsvpButton = ({
  onClick,
  className,
  url,
  isExternalLink,
  registrationWithCustomDates,
  eventId,
  narrowerButton,
  t,
}: SecondaryRsvpButtonProps) => {
  const {formattedRegistrationEndDate: date} = useEventDateInformation(eventId)
  const settings = useSettings()

  const visible = useAppSelector(isSecondRegistrationButtonVisible)
  const rsvpButtonText = settings.getWithFallback(settingsParams.rsvpEventButtonText, settingsParams.rsvpButtonText)

  if (!visible) {
    return null
  }

  return (
    <div className={classNames(s.container, s.spacing, className)}>
      <RegistrationButtonSkin
        secondary={true}
        text={rsvpButtonText}
        onClick={onClick}
        url={url}
        isExternalLink={isExternalLink}
        dataHook={DH.RSVP_BUTTON}
        narrowerButton={narrowerButton}
      />
      {registrationWithCustomDates && (
        <CustomDatesLabel text={t('registration.closesLabel', {date})} dataHook={DH.RSVP_CLOSES_LABEL} />
      )}
    </div>
  )
}
