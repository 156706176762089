import {useTranslation} from '@wix/yoshi-flow-editor'
import React from 'react'
import {useAppActions, useAppSelector} from '../../../hooks/runtime'
import {getScheduleItems, getScheduleItemsTotal} from '../../../selectors/schedule'
import {Footer} from '../../event-details/schedule/footer'
import {List} from '../../event-details/schedule/list'

export const MobileSchedule = () => {
  const {t} = useTranslation()
  const items = useAppSelector(getScheduleItems)
  const total = useAppSelector(getScheduleItemsTotal)
  const {navigateToSchedulePage} = useAppActions()

  return (
    <>
      <List t={t} items={items} mobile detailsContentAlignment />
      <Footer total={total} t={t} mobile navigateToSchedulePage={navigateToSchedulePage} />
    </>
  )
}
