import {isDraft} from '@wix/wix-events-commons-statics'
import {getEvent} from '../../../selectors/event'
import {DetailsPageAppProps} from '../../app/interfaces'
import {connect} from '../../runtime-context/context'
import {RsvpButtonStyleOwnProps, RuntimeProps} from './interfaces'
import {RegistrationButtonSkin as Presentation} from './registration-button-skin'

const mapRuntime = ({state}: DetailsPageAppProps): RuntimeProps => {
  const event = getEvent(state)

  return {
    draftEvent: !!event && isDraft(event),
  }
}

export const RegistrationButtonSkin = connect<RsvpButtonStyleOwnProps, RuntimeProps>(mapRuntime)(Presentation)
