import {useSlotPlaceholder} from '@wix/widget-plugins-ooi'
import {ExperimentNames, hasImage} from '@wix/wix-events-commons-statics'
import {detailsPageDataHooks as DH} from '@wix/wix-events-data-hooks'
import {useExperiments} from '@wix/yoshi-flow-editor'
import classNames from 'classnames'
import React from 'react'
import {SLOT_EVENT_DETAILS_HEADER} from '../../../../../../../commons/constants/slots'
import {useAppSelector} from '../../../../hooks/runtime'
import {useVisibilityStyles} from '../../../../hooks/use-visibility-styles'
import {getEvent} from '../../../../selectors/event'
import alignmentClasses from '../../alignment/alignment.scss'
import {RegistrationButton} from '../../registration-button'
import {Description} from '../description'
import {ShortDateLocation} from '../short-date-location'
import {Title} from '../title'
import s from './classic-layout.scss'
import {Image} from './image'

interface ClassicHeaderLayoutProps {
  onClickRegistrationButton: Function
}

export const ClassicLayout = ({onClickRegistrationButton}: ClassicHeaderLayoutProps) => {
  const {experiments} = useExperiments()
  const {
    allBreakpoints: {isEventImageVisible},
  } = useVisibilityStyles()

  const imageVisible = hasImage(useAppSelector(getEvent)) && isEventImageVisible()
  const [SlotPlaceholder, slotEmpty] = useSlotPlaceholder(SLOT_EVENT_DETAILS_HEADER)
  const slotsEnabled = experiments.enabled(ExperimentNames.EventDetailsSlots)

  return (
    <div className={classNames(s.wrapper, imageVisible && s.image)}>
      <div className={s.headerBackground} />
      <div className={s.container} data-hook={DH.header}>
        <div
          className={classNames(s.content, s.classicHeaderLayout, alignmentClasses.headerAlignment, {
            [s.contentWithImage]: imageVisible,
          })}
        >
          <Title className={s.eventTitle} />
          <ShortDateLocation />
          {slotsEnabled && !slotEmpty ? (
            <div className={s.slotHeaderContainer}>
              <SlotPlaceholder />
            </div>
          ) : null}
          <Description />
          <RegistrationButton onClick={onClickRegistrationButton} />
        </div>
        {imageVisible && (
          <div className={s.eventImage}>
            <Image />
          </div>
        )}
      </div>
    </div>
  )
}
